<template>
  <div class="root">
    <div class="search-box">
      <el-form ref="searchFormRef"
               :inline="true"
               :model="searchForm">
        <el-form-item v-for="(element, index) in searchElements"
                      :key="index"
                      :prop="element.model">
          <el-input v-if="element.type === 'input'"
                    v-model="searchForm[element.model]"
                    :placeholder="element.placeholder"
                    class="default-select-width"
                    clearable></el-input>
          <el-select v-if="element.type === 'select'"
                     v-model="searchForm[element.model]"
                     :placeholder="element.placeholder"
                     class="default-select-width"
                     clearable>
            <el-option v-for="item in element.options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
          <el-date-picker v-if="element.type === 'datetime'"
                          v-model="searchForm[element.model]"
                          :placeholder="element.placeholder"
                          type="datetime"
                          value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>

          <date-range-select-component
            v-if="element.type === 'daterange'"
            :date-range.sync="searchForm[element.model]"></date-range-select-component>
        </el-form-item>
        <el-form-item>
          <el-button :loading="tableLoading"
                     type="primary"
                     @click="searchTableData">查询
          </el-button>
          <el-button @click="resetFormFields">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table-box">
      <el-table v-loading="tableLoading"
                :data="tableData"
                stripe
                style="width: 100%">
        <template v-for="(column, index) in tableColumn">
          <el-table-column :key="index"
                           :fixed="column.fixed || false"
                           :label="column.label"
                           :min-width="column.minWidth"
                           :show-overflow-tooltip="column.showOverflowTooltip ? true : false">
            <template slot-scope="scope">
              <span v-if="column.clipboard"
                    :data-clipboard-text="
                  $helpers.v(scope.row, column.prop)
                "
                    class="clipboard">{{ $helpers.v(scope.row, column.prop) }}</span>
              <span v-else>{{
                  $helpers.v(scope.row, column.prop)
                }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
  </div>
</template>

<script>

import DateRangeSelectComponent from "../../components/DateRangeSelectComponent/DateRangeSelectComponent";

export default {
  name: "OrderAnalysis",
  components: {DateRangeSelectComponent},
  data() {
    return {
      searchElements: [
        {
          type: "daterange",
          model: "dateRange",
        },
      ],
      searchForm: {
        dateRange: [
          this.$moment().format('YYYY-MM-DD'),
          this.$moment().format('YYYY-MM-DD')
        ],
        start_time: '',
        end_time: ''
      },
      tableLoading: false,
      tableColumn: [
        {prop: "dig_event_id", label: "营销会议ID", minWidth: 100, fixed: true},
        {prop: "dig_event_title", label: "会议名称", minWidth: 240, fixed: true, showOverflowTooltip: true, clipboard: true},
        {prop: "order_total_count", label: "订单总数", minWidth: 100},
        {prop: "order_unpay_count", label: "待支付", minWidth: 80},
        {prop: "order_pay_count", label: "已支付", minWidth: 80},
        {prop: "order_cancel_count", label: "已取消", minWidth: 80},
        {prop: "order_refund_count", label: "已退款", minWidth: 100},
        {prop: "order_total_money", label: "总金额", minWidth: 80},
        {prop: "order_total_pay_money", label: "已收款", minWidth: 80},
      ],
      tableData: []
    };
  },
  methods: {
    getTableData() {
      this.tableLoading = true;
      this.$api.analysis
        .orderAnalysis({
          start_time: this.searchForm.dateRange[0],
          end_time: this.searchForm.dateRange[1]
        })
        .then((res) => {
          let {data} = res;
          this.tableData = data;
          this.tableLoading = false;
        });
    },
    searchTableData() {
      this.getTableData();
    },
    resetFormFields() {
      this.searchForm.dateRange = [
        this.$moment().format('YYYY-MM-DD'),
        this.$moment().format('YYYY-MM-DD')
      ];
    }
  },
  watch: {
    searchForm: {
      deep: true,
      handler() {
        this.getTableData()
      }
    }
  },
  created() {
    this.getTableData();
  },
};
</script>

<style lang="scss"
       scoped>
</style>
