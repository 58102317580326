<template>
  <el-date-picker
    v-model="_dateRange"
    type="daterange"
    align="right"
    unlink-panels
    range-separator="至"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
    value-format="yyyy-MM-dd"
    :clearable="false"
    :picker-options="pickerOptions">
  </el-date-picker>
</template>

<script>
export default {
  name: "DateRangeSelectComponent",
  props: ['dateRange'],
  data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  computed: {
    _dateRange: {
      get() {
        return this.dateRange
      },
      set(val) {
        this.$emit('update:dateRange', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
